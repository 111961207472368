import { useState, createContext, useContext, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [username, setUsernameState] = useState(null);
    const [userStatus, setUserStatusState] = useState(null);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedUserStatus = localStorage.getItem('userStatus');

        if (storedUsername) {
            setUsernameState(storedUsername);
        }
        if (storedUserStatus) {
            setUserStatusState(storedUserStatus);
        }

        const fetchUserProfile = async () => {
            if (storedUsername) {
                try {
                    const response = await axios.get(`/api/getProfile?username=${storedUsername}`);
                    const profile = response.data;
                    setUserStatusState(profile.status);
                    localStorage.setItem('userStatus', profile.status);
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                }
            }
        };

        fetchUserProfile();
    }, []);

    const setUsername = (username) => {
        setUsernameState(username);
        if (username) {
            localStorage.setItem('username', username);
        } else {
            localStorage.removeItem('username');
            setUserStatusState(null);
            localStorage.removeItem('userStatus');
        }
    };

    const setUserStatus = (status) => {
        setUserStatusState(status);
        if (status) {
            localStorage.setItem('userStatus', status);
        } else {
            localStorage.removeItem('userStatus');
        }
    };

    return (
        <UserContext.Provider value={{ username, setUsername, userStatus, setUserStatus }}>
            {children}
        </UserContext.Provider>
    );
};
import React from "react";
import "./style/resources.scss";

const Resources = () => {
  const technologies = [
    // Frontend Technologies
    { name: "React", img: "/assets/icons/reactjs.png", description: "Dynamic user interfaces" },
    { name: "AngularJS", img: "/assets/icons/angularjs.png", description: "Scalable web apps" },
    { name: "Svelte", img: "/assets/icons/svetle.png", description: "Lightweight frontend development" },
    { name: "Meteor", img: "/assets/icons/meteor.png", description: "Full-stack JavaScript framework" },
    { name: "NextJS", img: "/assets/icons/nextjs.png", description: "React framework for server-side rendering" },
    { name: "ViteJS", img: "/assets/icons/vitejs.png", description: "Fast frontend build tool" },
    { name: "TailwindCSS", img: "/assets/icons/tailwindcss.png", description: "Utility-first CSS framework" },
    { name: "SCSS", img: "/assets/icons/sass.png", description: "Sass-based CSS preprocessor" },
    { name: "PWA", img: "/assets/icons/pwa.png", description: "Progressive Web Apps for enhanced web experience" },

    // Backend Technologies
    { name: "NodeJS", img: "/assets/icons/nodejs.png", description: "Server-side JavaScript runtime" },
    { name: "ExpressJS", img: "/assets/icons/expressjs.png", description: "Backend framework for NodeJS" },
    { name: "PHP", img: "/assets/icons/php.png", description: "Server-side scripting language" },
    { name: "Ruby", img: "/assets/icons/ruby.png", description: "General-purpose programming language" },
    { name: "Ruby on Rails", img: "/assets/icons/rubyonrails.png", description: "Web framework for Ruby" },
    { name: "Laravel", img: "/assets/icons/laravel.png", description: "PHP framework for web applications" },
    { name: "Lua", img: "/assets/icons/lua.png", description: "Lightweight scripting language" },
    { name: "Pawn", img: "/assets/icons/pawn.png", description: "Scripting language for SA-MP" },

    // Database Technologies
    { name: "MySQL", img: "/assets/icons/mysql.png", description: "Relational database management" },
    { name: "MongoDB", img: "/assets/icons/mongoDB.png", description: "NoSQL database" },

    // Content Management Systems (CMS)
    { name: "WordPress", img: "/assets/icons/wordpress.png", description: "Popular CMS for websites" },
    { name: "Flarum", img: "/assets/icons/flarum.png", description: "Forum software for online communities" },
    { name: "SMF Forum", img: "/assets/icons/smf.png", description: "Simple Machines Forum" }
  ];

  const tools = [
    { name: "VSCode", img: "/assets/icons/vscode.png", description: "Code editor by Microsoft" },
    { name: "Project IDX", img: "/assets/icons/projectidx.png", description: "Google’s cloud-based IDE" },
    { name: "JetBrains Suite", img: "/assets/icons/jetbrains.png", description: "IDE tools for various languages" },
    { name: "Trello", img: "/assets/icons/trello.png", description: "Project management and organization" },
    { name: "GitHub", img: "/assets/icons/github.png", description: "Code hosting platform" },
    { name: "Google Drive", img: "/assets/icons/googledrive.png", description: "Cloud storage solution" },
    { name: "Affinity Suite", img: "/assets/icons/affinity.png", description: "Graphic design tools" },
    { name: "Figma", img: "/assets/icons/figma.png", description: "Collaborative interface design tool" },
    { name: "Capcut", img: "/assets/icons/capcut.png", description: "Video editing software" },
    { name: "DaVinci Resolve", img: "/assets/icons/davinciresolve.png", description: "Professional video editing" },
    { name: "FL Studio", img: "/assets/icons/flstudio.png", description: "Digital audio workstation" },
    { name: "Rekordbox", img: "/assets/icons/rekordbox.png", description: "DJ software for music management" }
  ];

  const hostingProviders = [
    { name: "Hostinger Hosting", url: "https://www.hostinger.com", img: "/assets/icons/hostinger.png" },
    { name: "Contabo Hosting", url: "https://contabo.com", img: "/assets/icons/contabo.png" }
  ];

  return (
      <div id="resources" className="resources">
        <h1>Our Resources</h1>
        <p className="intro-text">
          Our expertise spans across various frameworks, programming languages, and tools to ensure we deliver the best products to our clients.
        </p>

        <div className="resources__section">
          <h2>Technologies We Use</h2>
          <div className="tech__grid">
            {technologies.map((tech, index) => (
                <div className="tech__item" key={index}>
                  <img src={tech.img} alt={tech.name} width="50" height="50" />
                  <div>
                    <strong>{tech.name}</strong>
                    <p>{tech.description}</p>
                  </div>
                </div>
            ))}
          </div>
        </div>

        <div className="resources__section">
          <h2>Tools We Use</h2>
          <div className="tools__grid">
            {tools.map((tool, index) => (
                <div className="tools__item" key={index}>
                  <img src={tool.img} alt={tool.name} width="50" height="50" />
                  <div>
                    <strong>{tool.name}</strong>
                    <p>{tool.description}</p>
                  </div>
                </div>
            ))}
          </div>
        </div>

        <div className="resources__section">
          <h2>Hosting Providers</h2>
          <div className="hosting__grid">
            {hostingProviders.map((provider, index) => (
                <a href={provider.url} target="_blank" rel="noopener noreferrer" key={index} className="hosting__card">
                  <img src={provider.img} alt={provider.name} />
                  <span>{provider.name}</span>
                </a>
            ))}
          </div>
        </div>
      </div>
  );
};

export default Resources;
import React from "react";
import "./style/hero.scss";

const Hero = () => {
  return (
    <div id="home" className="hero">
      <video
        className="hero-video"
        autoPlay
        loop
        muted
        src="/assets/header-video.mp4"
        type="video/mp4"
      />
      <div className="hero-content">
        <img
          src="/assets/Logo Semi Colored.png"
          alt="Logo"
          className="hero-logo"
          draggable="true" // Allow the logo to be dragged
        />
        <a href="#about">
          <button className="hero-button"><strong>Get Started</strong></button>
        </a>
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';
import './landingpage.scss'; // Optional: Create a CSS file for styling
import Navbar from './pages/Navbar'; // Adjust the path as necessary
import Hero from './pages/Hero'; // Adjust the path as necessary
import Services from './pages/Services'; // Adjust the path as necessary
import Resources from './pages/Resources'; // Adjust the path as necessary
import Projects from './pages/Projects'; // Adjust the path as necessary
import About from './pages/About'; // Adjust the path as necessary
import Footer from './pages/Footer'; // Adjust the path as necessary

const LandingPage = () => {
    return (
        <div className="landing">
            <Navbar />  {/* Display the Navbar */}
            <Hero />  {/* Display the Hero */}
            <Services />  {/* Display the Hero */}
            <Resources />  {/* Display the Hero */}
            <Projects />  {/* Display the Hero */}
            <About />  {/* Display the Hero */}
            <Footer />  {/* Display the Hero */}
        </div>
    );
};

export default LandingPage;

import React from "react";
import "./style/about.scss";

const About = () => {
  return (
      <div className="about-container">
        <div id="about" className="about">
          <video
              className="about-video"
              autoPlay
              loop
              muted
              src="/assets/header-video.mp4"
              type="video/mp4"
          />
          <div className="about-content">
            {/* About Section */}
            <div className="additional-info">
              <h2>About Us</h2>
              <p>
                At DSRL, we believe in the power of ideas. Our mission is to empower startups and
                individuals to turn their vision into reality by offering a range of services tailored
                to their needs. Whether it's developing digital solutions or building strategic business
                collaborations, DSRL is committed to driving success.
              </p>
              <p>
                Join us on this journey as we explore new horizons and create opportunities that shape
                the future of innovation.
              </p>
            </div>

            {/* Founding Section */}
            <div className="founding">
              <h2>The Founding of DSRL</h2>
              <p>
                DSRL was founded with a vision to become a leader in innovation and digital services.
                The idea behind creating the company emerged from the desire to combine creative
                concepts with business innovations, recognizing the need for a company that could
                support the development of startups and groundbreaking projects on a global scale.
              </p>
              <p>
                The core idea driving the establishment of DSRL was to create a platform that fosters
                creativity and supports individuals and teams in realizing their projects through
                professional services. As an investment firm, DSRL focuses on developing, supporting,
                and implementing startup ideas, engaging in business collaborations, and distributing
                personal services across various markets.
              </p>
              <p>
                Based in Tbilisi, Georgia, DSRL set its sights on becoming a central hub for innovative
                projects in the digital world, expanding globally through partnerships and by exploring
                new markets.
              </p>
            </div>

            {/* Meet a Founder Section */}
            <div className="meet-founder">
              <h2>Meet a Founder</h2>
              <div className="founder-profile">
                <img src="/assets/profilepicture.png" alt="Founder" className="founder-image" />
                <div className="founder-info">
                  <h3>Nenad Petrovic</h3>
                  <h5>@naithdriver</h5>
                  <p>
                    Naithan is the visionary behind DSRL, bringing years of experience in digital services
                    and startup development. His passion for innovation drives the company's mission
                    to empower others.
                  </p>
                  <a href="https://naithdriver.com" target="_blank" rel="noopener noreferrer">
                    View Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default About;
// src/context/TicketContext.js

import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const TicketContext = createContext();

export const useTicket = () => {
    const context = useContext(TicketContext);
    if (!context) {
        throw new Error('useTicket must be used within a TicketProvider');
    }
    return context;
};

export const TicketProvider = ({ children }) => {
    const [tickets, setTickets] = useState([]);

    const fetchTickets = async (username) => {
        try {
            const response = await axios.get(`http://api.dreamstrl.net/api/tickets?username=${username}`);
            setTickets(response.data);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    const addTicket = async (ticket) => {
        try {
            await axios.post('http://api.dreamstrl.net/api/tickets', ticket);
            fetchTickets(ticket.username); // Refresh the list
        } catch (error) {
            console.error('Error adding ticket:', error);
        }
    };

    const updateTicket = async (id, ticket) => {
        try {
            await axios.put(`http://api.dreamstrl.net/api/tickets/${id}`, ticket);
            fetchTickets(ticket.username); // Refresh the list
        } catch (error) {
            console.error('Error updating ticket:', error);
        }
    };

    const deleteTicket = async (id, username) => {
        try {
            await axios.delete(`http://api.dreamstrl.net/api/tickets/${id}`);
            fetchTickets(username); // Refresh the list
        } catch (error) {
            console.error('Error deleting ticket:', error);
        }
    };

    return (
        <TicketContext.Provider value={{ tickets, fetchTickets, addTicket, updateTicket, deleteTicket }}>
            {children}
        </TicketContext.Provider>
    );
};
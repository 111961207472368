import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../../userContext';
import './style/profile.scss';

const Profile = () => {
    const initials = 'NP'; // Example initials, can be dynamically generated
    const { username } = useUser(); // Get username from context
    const [user, setUser] = useState({
        username: '',
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        email: '',
        phone: '',
    });

    // Fetch user profile data on component mount
    useEffect(() => {
        if (!username) {
            console.error('Username is not available. The user might not be logged in.');
            return;
        }

        axios.get('http://api.dreamstrl.net/api/getProfile', { params: { username } })
             .then((response) => {
                 setUser({
                     username: response.data.username,
                     firstName: response.data.first_name,
                     lastName: response.data.last_name,
                     country: response.data.country,
                     city: response.data.city,
                     email: response.data.email,
                     phone: response.data.phone,
                 });
             })
             .catch((error) => {
                 console.error('There was an error fetching the profile data!', error);
             });
    }, [username]);

    return (
    <div className="profile">
        <div className="profile__box">
            <div className="profile__initials">{initials}</div>
            </div>
            <div className="profile__info">
                <div className="profile__details-box">
                    <div className="profile__details"><strong>First Name:</strong> {user.firstName}</div>
                </div>
                <div className="profile__details-box">
                    <div className="profile__details"><strong>Last Name:</strong> {user.lastName}</div>
                </div>
                <div className="profile__details-box">
                    <div className="profile__details"><strong>Username:</strong> {user.username}</div>
                </div>
                <div className="profile__details-box">
                    <div className="profile__details"><strong>Country:</strong> {user.country}</div>
                </div>
                <div className="profile__details-box">
                    <div className="profile__details"><strong>City:</strong> {user.city}</div>
                </div>
                <div className="profile__details-box">
                    <div className="profile__details"><strong>Email:</strong> {user.email}</div>
                </div>
                <div className="profile__details-box">
                    <div className="profile__details"><strong>Phone:</strong> {user.phone}</div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
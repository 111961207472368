import React, { useState, useEffect } from 'react';
import { useService } from '../../serviceContext';
import { useUser } from '../../userContext';
import axios from 'axios';
import './style/servicecontrol.scss';

const ServiceControl = () => {
    const { services, fetchServices, addService, updateService, deleteService } = useService();
    const { username } = useUser();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);  // New State for Selected User
    const [formData, setFormData] = useState({
        usluga: '',
        status: '',
        naziv: '',
        cena: '',
        trajeDo: '',
        cenaObnove: ''
    });
    const [editingService, setEditingService] = useState(null);

    useEffect(() => {
        if (username) {
            fetchServices(username);
            fetchUsers(); // Fetch users when the component mounts
        }
    }, [username, fetchServices]);

    useEffect(() => {
        if (!selectedUser) {
            return;
        }
        fetchServices(selectedUser);
    }, [selectedUser, fetchServices]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('http://api.dreamstrl.net/api/users');
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value); // Update selected user state
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const service = { ...formData, username: selectedUser }; // Use selected user for service operations

        if (editingService) {
            updateService(editingService.id, service);
        } else {
            addService(service);
        }

        setFormData({
            usluga: '',
            status: '',
            naziv: '',
            cena: '',
            trajeDo: '',
            cenaObnove: ''
        });
        setEditingService(null);
    };

    const startEditing = (service) => {
        setFormData(service);
        setEditingService(service);
    };

    const cancelEditing = () => {
        setFormData({
            usluga: '',
            status: '',
            naziv: '',
            cena: '',
            trajeDo: '',
            cenaObnove: ''
        });
        setEditingService(null);
    };

    return (
        <div className="service-control">
            <h2>Service Control</h2>
            <form onSubmit={handleSubmit}>
                <select name="selectedUser" onChange={handleUserChange} value={selectedUser || ''} required>
                    <option value="" disabled>Select User</option>
                    {users.map(user => (
                        <option key={user.id} value={user.username}>{user.username}</option>
                    ))}
                </select>
                <input type="text" name="usluga" value={formData.usluga} onChange={handleChange} placeholder="Service Name" required />
                <input type="text" name="status" value={formData.status} onChange={handleChange} placeholder="Status" required />
                <input type="text" name="naziv" value={formData.naziv} onChange={handleChange} placeholder="Name" required />
                <input type="text" name="cena" value={formData.cena} onChange={handleChange} placeholder="Price" required />
                <input type="date" name="trajeDo" value={formData.trajeDo} onChange={handleChange} placeholder="Valid Until" required />
                <input type="text" name="cenaObnove" value={formData.cenaObnove} onChange={handleChange} placeholder="Renewal Price" required />
                <button type="submit">{editingService ? 'Update Service' : 'Add Service'}</button>
                {editingService && <button type="button" onClick={cancelEditing}>Cancel</button>}
            </form>
            <div className="service-list">
                {services && services.map(service => (
                    <div key={service.id} className="service-item">
                        <h3>{service.naziv}</h3>
                        <p><strong>Status:</strong> {service.status}</p>
                        <p><strong>Price:</strong> {service.cena}</p>
                        <p><strong>Valid Until:</strong> {service.trajeDo}</p>
                        <p><strong>Renewal Price:</strong> {service.cenaObnove}</p>
                        <button onClick={() => startEditing(service)}>Edit</button>
                        <button onClick={() => deleteService(service.id, selectedUser)}>Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceControl;
import React, { useState } from 'react';
import './style/services.scss';

const Services = () => {
  const [activeService, setActiveService] = useState('overview');
  const [budget, setBudget] = useState(null);

  const serviceDetails = {
    overview: "Explore our range of professional services to boost your business and digital presence.",
    websites: "Our website services provide beautifully designed and optimized sites.",
    hosting: "Reliable and secure hosting solutions to keep your business online.",
    design: "Creative design services tailored to your brand's identity.",
  };

  const budgetOptions = [
    { label: "Basic", value: "Basic" },
    { label: "Standard", value: "Standard" },
    { label: "Premium", value: "Premium" },
    { label: "Custom Deal", value: "custom" },
  ];

  const digitalOffers = {
    "Basic": (
      <>
        <h4>Basic Digital Package</h4>
        <p>Includes essential automation tools and a simple software solution.</p>
      </>
    ),
    "Standard": (
      <>
        <h4>Standard Digital Package</h4>
        <p>Offers software tailored to your business needs with advanced automation tools.</p>
      </>
    ),
    "Premium": (
      <>
        <h4>Premium Digital Package</h4>
        <p>Comprehensive solutions with custom software development and full integration.</p>
      </>
    ),
  };

  const hostingOffers = {
    "Basic": (
      <>
        <h4>Starter Hosting Plan</h4>
        <h3>Price: $60</h3>
        <p>
          A standard-speed hosting package for small websites with a limited 
          amount of media content displayed, as well as for small webstore 
          servers.
        </p>
      </>
    ),
    "Standard": (
      <>
        <h4>Standard Hosting Plan</h4>
        <h3>Price: $90</h3>
        <p>
          A standard-speed hosting package for medium-sized websites with an 
          average amount of media content displayed, as well as for standard 
          webstore servers.
        </p>
      </>
    ),
    "Premium": (
      <>
        <h4>Premium Hosting Plan</h4>
        <h3>Price: $120</h3>
        <p>
          A premium-speed hosting package for large websites with a significant 
          amount of media content displayed, as well as for larger webstore 
          servers.
        </p>
      </>
    ),
  };

  const websiteDescriptions = {
    "Basic": (
      <>
        <h4>Entry-Level Website Package</h4>
        <h3>Price: $200 - $350</h3>
        <p>
          A modern, minimalist one-page website with a landing page and optional basic web store. 
          Ideal for startups and small businesses.
        </p>
        <p>
          Includes hosting package and a domain for one year. Basic SEO is also provided.
          30% upfront payment required.
        </p>
      </>
    ),
    "Standard": (
      <>
        <h4>Standard Website Package</h4>
        <h3>Price: $400 - $650</h3>
        <p>
          A sleek website with a home page and five additional pages. This package is ideal 
          for businesses needing more robust features and a professional online presence.
        </p>
        <p>
        Includes hosting package and a domain for one year. Standard SEO is also provided.
        30% upfront payment required.
        </p>
      </>
    ),
    "Premium": (
      <>
        <h4>Premium Website Package</h4>
        <h3>Price: $700 - $950</h3>
        <p>
          A modern website with 10+ pages and an advanced online store. Perfect for businesses 
          that need complex features and enhanced performance.
        </p>
        <p>
          Includes hosting package and a domain for one year. Premium SEO is also provided.
          30% upfront payment required.
        </p>
      </>
    ),
  };

  const designOffers = {
    "Basic": (
      <>
        <h4>Basic Design Package</h4>
        <h3>Price: $30 - $70</h3>
        <p>
          Includes logo, cover, social media post or web design based on client 
          preferences, featuring a modern minimalist style. Up to three concepts 
          are provided.
        </p>
      </>
    ),
    "Standard": (
      <>
        <h4>Standard Design Package</h4>
        <h3>Price: $100 - $200</h3>
        <p>
          Includes logo, cover, social media post or web design tailored to 
          client needs, available in either a modern minimalist or modern 
          detailed style. Up to five concepts are provided.
        </p>
      </>
    ),
    "Premium": (
      <>
        <h4>Premium Design Package</h4>
        <h3>Price: $250 - $400</h3>
        <p>
          Comprehensive design package featuring logo, cover, social media post
          or web design as per client requirements, available in either a modern 
          minimalist or modern detailed style. Up to ten concepts are provided.
        </p>
      </>
    ),
  };
  

  const managementOffers = {
    "Basic": (
      <>
        <h4>Basic Management Package</h4>
        <p>Project tracking tools with limited user access.</p>
      </>
    ),
    "Standard": (
      <>
        <h4>Standard Management Package</h4>
        <p>Advanced tools with analytics and multiple user access.</p>
      </>
    ),
    "Premium": (
      <>
        <h4>Premium Management Package</h4>
        <p>Full management suite with CRM, analytics, and automation tools.</p>
      </>
    ),
  };

  const businessOffers = {
    "Basic": (
      <>
        <h4>Basic Business Consulting</h4>
        <p>Initial consultation and market analysis.</p>
      </>
    ),
    "Standard": (
      <>
        <h4>Standard Business Consulting</h4>
        <p>Comprehensive business planning and financial strategy.</p>
      </>
    ),
    "Premium": (
      <>
        <h4>Premium Business Consulting</h4>
        <p>Complete business solutions with mentorship and growth strategy.</p>
      </>
    ),
  };

  const handleBudgetSelect = (value) => setBudget(value);

  const renderCustomDeal = () => (
    <div className="custom__deal">
      <h4>Custom Deal Request</h4>
      <p>
        Have something specific in mind? We can create a tailored solution just for you!
        Please contact us at <a href="mailto:customdeal@dreamstrl.com">customdeal@dreamstrl.com</a> with your requirements.
      </p>
    </div>
  );

  const renderOffers = (offers) => (
    <div className="budget__selection">
      <h2>{activeService.charAt(0).toUpperCase() + activeService.slice(1)} Packages</h2>
      <p>Select package to view the offer.</p>
      <div className="budget__buttons">
        {budgetOptions.map((option) => (
          <button
            key={option.value}
            className={`budget__button ${budget === option.value ? 'active' : ''}`}
            onClick={() => handleBudgetSelect(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
      {budget === 'custom' ? renderCustomDeal() : budget && <div className="service__offers">{offers[budget]}</div>}
    </div>
  );

  return (
    <div id="services" className="services">
      <h1 className="services__title">Our Services</h1>
      <p className="subheader">Discover tailored solutions to meet your business needs.</p>

      <div className="services__buttons">
        {Object.keys(serviceDetails).map((key) => (
          <button
            key={key}
            className={`service__button ${activeService === key ? 'active' : ''}`}
            onClick={() => {
              setActiveService(key);
              setBudget(null);
            }}
          >
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </button>
        ))}
      </div>

      <div className="service__details">{serviceDetails[activeService]}</div>

      {activeService === 'websites' && renderOffers(websiteDescriptions)}
      {activeService === 'digital' && renderOffers(digitalOffers)}
      {activeService === 'hosting' && renderOffers(hostingOffers)}
      {activeService === 'design' && renderOffers(designOffers)}
      {activeService === 'management' && renderOffers(managementOffers)}
      {activeService === 'business' && renderOffers(businessOffers)}

      {activeService === 'overview' && (
        <div className="special__deals">
          <h3>Special Deals</h3>
          <p>
              We currently don't have any special offers. There may be some available soon.
          </p>
        </div>
      )}
    </div>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLaptop, FaBookOpen, FaProjectDiagram, FaHome, FaEnvelope } from 'react-icons/fa';
import './style/navbar.scss';

const Navbar = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleScrollNavigation = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [hovered, setHovered] = useState(null);
  const [isAtTop, setIsAtTop] = useState(true);

  const handleMouseEnter = (index) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  useEffect(() => {
    // Handle scroll event
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
      <nav className={`navbar ${!isAtTop ? 'hidden' : ''}`}>
        {/* Left Side */}
        <ul className="navbar__left">
          <li
              onClick={() => handleScrollNavigation('#services')}
              onMouseEnter={() => handleMouseEnter(0)}
              onMouseLeave={handleMouseLeave}
          >
            <FaLaptop />
          </li>
          <li
              onClick={() => handleScrollNavigation('#resources')}
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
          >
            <FaBookOpen />
          </li>
          <li
              onClick={() => handleScrollNavigation('#projects')}
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
          >
            <FaProjectDiagram />
          </li>
        </ul>

        {/* Center - Logo */}
        <div className="navbar__logo">
          <img src="/logo512.png" alt="DREAMSTRL Logo" />
          {hovered === 0 && <span className="navbar__tooltip">SERVICES</span>}
          {hovered === 1 && <span className="navbar__tooltip">RESOURCES</span>}
          {hovered === 2 && <span className="navbar__tooltip">PROJECTS</span>}
        </div>

        {/* Right Side */}
        <ul className="navbar__right">
          <li
              onClick={() => handleScrollNavigation('#home')}
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={handleMouseLeave}
          >
            <FaHome />
          </li>
          <li
              // Change this line to use a mailto link
              onClick={() => window.location.href = 'mailto:contact@dreamstrl.com'}
              onMouseEnter={() => handleMouseEnter(4)}
              onMouseLeave={handleMouseLeave}
          >
            <FaEnvelope />
          </li>
        </ul>
      </nav>
  );
};

export default Navbar;
import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import './style/projects.scss';

const projectsData = [
    // Websites
    {
        title: 'BeltsShop',
        type: 'website',
        description: 'BeltsShop offers an impressive online presence with a bespoke website, leveraging the power of ReactJS and TailwindCSS. Integrated PWA features ensure a seamless and engaging user experience, anytime and anywhere.',
        image: '/assets/customers/autopojasevi.png',
        link: 'https://autopojasevi.rs',
    },
    {
        title: 'Binis',
        type: 'website',
        description: 'The Binis website combines elegant design with high performance, built using ReactJS and SCSS. PWA features ensure the site offers an optimal user experience across all devices.',
        image: '/assets/customers/binisweb.png',
        link: 'https://apartkompleksbinis.com',
    },
    {
        title: 'Rent A Car DNDX',
        type: 'website',
        description: 'The Rent A Car DNDX site is created with NextJS and TailwindCSS, providing a modern design and reliability. With a user-friendly interface, this website makes car rental easy and accessible online.',
        image: '/assets/customers/rentacardndx.png',
        link: 'https://rentacardndx.com',
    },
    {
        title: 'Janami Wood',
        type: 'website',
        description: 'The Janami Wood site represents a perfect blend of aesthetics and functionality. With integrated PWA features and technologies like ReactJS and SCSS, the site ensures a top-notch experience for users exploring wooden products.',
        image: '/assets/customers/janamiwood.png',
        link: 'https://janamiwood.com',
    },

    // Design
    {
        title: 'Overwolf',
        type: 'design',
        description: 'The Overwolf logo is specially designed to meet the client’s needs, boasting a modern and attractive look that grabs attention.',
        image: '/assets/design/wolflogo.jpg',
        link: '/assets/design/wolflogo.jpg',
    },
    {
        title: 'CYFormat',
        type: 'design',
        description: 'The CYFormat logo design is characterized by a unique style that reflects the brand’s identity, carefully crafted to leave a lasting impression.',
        image: '/assets/design/cyf.jpg',
        link: '/assets/design/cyf.jpg',
    },
    {
        title: 'Zephyrs',
        type: 'design',
        description: 'The Zephyrs logo design combines creativity and professionalism, creating a distinctive visual identity that meets all the client’s requirements.',
        image: '/assets/design/zephyrs.jpg',
        link: '/assets/design/zephyrs.jpg',
    },
    {
        title: 'Montrey Community',
        type: 'design',
        description: 'The Montrey Community logo is created with attention to detail, providing a visual identity that perfectly captures the spirit of the community.',
        image: '/assets/design/montrey.jpg',
        link: '/assets/design/montrey.jpg',
    },
    {
        title: 'Routing Hosting',
        type: 'design',
        description: 'The Routing Hosting logo design is meticulously crafted to reflect the professionalism and technical superiority of the services offered by the company.',
        image: '/assets/design/routing.jpg',
        link: '/assets/design/routing.jpg',
    },
    {
        title: 'Whrlbe',
        type: 'design',
        description: 'The Whrlbe logo resulted in an elegant and modern design, tailored to match the brand’s specifics and client’s needs.',
        image: '/assets/design/whrlbe.jpg',
        link: '/assets/design/whrlbe.jpg',
    },
    {
        title: 'Whytellon',
        type: 'design',
        description: 'The Whytellon logo represents a harmonious blend of tradition and innovation, carefully created to ensure recognizability and long-term value.',
        image: '/assets/design/whytellon.jpg',
        link: '/assets/design/whytellon.jpg',
    },
    {
        title: 'Void Community',
        type: 'design',
        description: 'The Void Community logo is designed to reflect the unique character of the community, emphasizing recognizability and visual appeal.',
        image: '/assets/design/voidcommunity.jpg',
        link: '/assets/design/voidcommunity.jpg',
    },
    {
        title: 'Nordkapp Community',
        type: 'design',
        description: 'The Nordkapp Community logo is carefully designed to symbolize unity and accessibility, providing a refreshing visual identity.',
        image: '/assets/design/nordkapplogo.jpg',
        link: '/assets/design/nordkapplogo.jpg',
    },
    {
        title: 'Defacto Division',
        type: 'design',
        description: 'The Defacto Division logo design stands out with its unique and recognizable style, crafted to reflect the organization’s mission and values.',
        image: '/assets/design/defactodivision.jpg',
        link: '/assets/design/defactodivision.jpg',
    },
    {
        title: 'LBS Community',
        type: 'design',
        description: 'The LBS Community logo blends elegance and functionality, creating a distinctive and impactful visual identity for the brand.',
        image: '/assets/design/lbscommunity.jpg',
        link: '/assets/design/lbscommunity.jpg',
    },
    {
        title: 'Underground Community',
        type: 'design',
        description: 'The Underground Community logo design reflects authenticity and creativity, establishing an identity that stands out and inspires.',
        image: '/assets/design/underground.jpg',
        link: '/assets/design/underground.jpg',
    }

].map(project => ({...project, id: uuidv4()})); // Generate unique IDs

const Projects = () => {
    const [activeType, setActiveType] = useState('all'); // State for project type filtering

    const filteredProjects = projectsData.filter(
        (project) => activeType === 'all' || project.type === activeType
    );

    return (
        <div id="projects" className="projects">
            <h1 className="projects-title">Our Projects</h1>
            <h2 className="projects-subtitle">Explore our diverse portfolio of projects tailored to meet client needs,
                showcasing our expertise.</h2>

            {/* Sub-category Type Icons */}
            <div className="project-types">
                {['all', 'website', 'design'].map((type) => (
                    <button
                        key={type}
                        className={`project-type-button ${activeType === type ? 'active' : ''}`}
                        onClick={() => setActiveType(type)}
                    >
                        <i className={`fas fa-${type === 'website' ? 'globe' : 'paint-brush'}`}></i>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </button>
                ))}
            </div>

            {/* Project Cards */}
            <div className="projects-list">
                {filteredProjects.map((project) => (
                    <div key={project.id} className="project">
                        <a
                            href={project.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="project-image"
                        >
                            <img src={project.image} alt={project.title}/>
                        </a>
                        <h2 className="project-title">{project.title}</h2>
                        <span className="project-type">{project.type}</span> {/* Displaying type */}
                        <p className="project-description">{project.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projects;
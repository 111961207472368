import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLaptop, FaUserCircle, FaEnvelope, FaArrowLeft } from 'react-icons/fa';
import './style/navbar.scss';
import { useUser } from '../../userContext';

const MNavbar = () => {
    const navigate = useNavigate();
    const { username, setUsername, userStatus } = useUser();

    const handleLogout = () => {
        setUsername(null);
        navigate('/authorisation');
    };

    const [hovered, setHovered] = useState(null);
    const [isAtTop, setIsAtTop] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleMouseEnter = (index) => {
        setHovered(index);
    };

    const handleMouseLeave = () => {
        setHovered(null);
    };

    const handleLogoClick = () => {
        console.log('Logo clicked');

        if (username === 'naithdriver') {
            console.log('User is "naithdriver", navigating to /dashboard');
            navigate('/dashboard');
        } else {
            console.log('User is not authorized to navigate to /dashboard');
            alert('You are not authorized to access the dashboard.');
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsAtTop(window.scrollY === 0);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleScrollNavigation = (id) => {
        const section = document.querySelector(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <nav className={`mnavbar ${!isAtTop ? 'hidden' : ''}`}>
            <ul className="mnavbar__left">
                <li
                    onClick={handleLogout}
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                >
                    <FaArrowLeft />
                </li>
            </ul>

            <div
                className={`mnavbar__logo ${isMobile ? 'disabled' : ''}`}
                onClick={!isMobile && (userStatus === 'Admin' || userStatus === 'Moderator' || username === 'naithdriver') ? handleLogoClick : undefined}
                style={{ cursor: isMobile ? 'default' : (userStatus === 'Admin' || userStatus === 'Moderator' || username === 'naithdriver' ? 'pointer' : 'default') }}
            >
                <img src="/logo512.png" alt="DREAMSTRL Logo" />
                {hovered === 0 && <span className="mnavbar__tooltip">LOGOUT</span>}
                {hovered === 1 && <span className="mnavbar__tooltip">ACCOUNT</span>}
                {hovered === 2 && <span className="mnavbar__tooltip">SERVICES</span>}
                {hovered === 3 && <span className="mnavbar__tooltip">TICKET</span>}
            </div>

        </nav>
    );
};

export default MNavbar;
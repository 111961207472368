import React, { useState, useEffect } from 'react';
import { useService } from '../../serviceContext';
import { useUser } from '../../userContext';
import './style/services.scss';

// Define a service box component
const ServiceBox = ({ service, expanded, onClick }) => (
    <div className={`service-box ${expanded ? 'expanded' : ''}`} onClick={onClick}>
        <div className="service-header">{service.naziv}</div>
        {expanded && (
            <>
                <p>
                    <span className="service-label">Service:  </span>
                    <span className="service-value">{service.usluga}</span>
                </p>
                <p>
                    <span className="service-label">Status:  </span>
                    <span className="service-value">{service.status}</span>
                </p>
                <p>
                    <span className="service-label">Price:  </span>
                    <span className="service-value">{service.cena}</span>
                </p>
                <p>
                    <span className="service-label">Renew:  </span>
                    <span className="service-value">{service.trajeDo}</span>
                </p>
                <p>
                    <span className="service-label">Renew Price:  </span>
                    <span className="service-value">{service.cenaObnove}</span>
                </p>
            </>
        )}
    </div>
);

const MServices = () => {
    const { services, fetchServices } = useService();
    const { username } = useUser();
    const [expandedService, setExpandedService] = useState(null);

    useEffect(() => {
        if (username) {
            fetchServices(username);
        }
    }, [username, fetchServices]);

    const toggleExpand = (index) => {
        setExpandedService(expandedService === index ? null : index);
    };

    return (
        <div className="mservices">
            {services.map((service, index) => (
                <ServiceBox
                    key={index}
                    service={service}
                    expanded={expandedService === index}
                    onClick={() => toggleExpand(index)}
                />
            ))}
        </div>
    );
};

export default MServices;
import React from 'react';
import './style/footer.scss';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="mfooter">
            <div className="mfooter-content">
                <div className="mfooter-icons">
                    <a href="https://facebook.com/dreamstrl" target="_blank" rel="noopener noreferrer">
                        <FaFacebook/>
                    </a>
                    <a href="https://instagram.com/dsrlcom" target="_blank" rel="noopener noreferrer">
                        <FaInstagram/>
                    </a>
                    <a href="https://tiktok.com/dsrlcom" target="_blank" rel="noopener noreferrer">
                        <FaTiktok/>
                    </a>
                </div>
                <p>© {new Date().getFullYear()} <a href="https://dreamstrl.com" target="_blank"
                                                   rel="noopener noreferrer">dreamstrl.com</a> - All rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './dashboardpage.scss';
import Customers from './pages/Customers';
import ServiceControl from './pages/ServiceControl';
// Import icons for the sidebar
import { FaUser, FaServicestack, FaTicketAlt, FaArrowLeft } from 'react-icons/fa';

const DashboardPage = () => {
    const [selectedSection, setSelectedSection] = useState('customers');
    const navigate = useNavigate();

    let content;
    switch (selectedSection) {
        case 'customers':
            content = <Customers />;
            break;
        case 'serviceControl':
            content = <ServiceControl />;
            break;
        default:
            content = <Customers />;
    }

    return (
        <div className="dashboard-page">
            <video
                className="main-video"
                autoPlay
                loop
                muted
                src="/assets/header-video.mp4"
                type="video/mp4"
            />
            <div className="sidebar">
                <div className="icon" onClick={() => setSelectedSection('customers')}>
                    <FaUser size={30} />
                </div>
                <div className="icon" onClick={() => setSelectedSection('serviceControl')}>
                    <FaServicestack size={30} />
                </div>
                <div className="icon go-back" onClick={() => navigate('/main')}>
                    <FaArrowLeft size={30} />
                </div>
            </div>
            <div className="content-panel">
                {content}
            </div>
        </div>
    );
};

export default DashboardPage;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../userContext'; // Adjust import if needed
import './authpage.scss';

const AuthPage = () => {
    const { setUsername, username } = useUser();
    const [isLogin, setIsLogin] = useState(true);
    const [usernameInput, setUsernameInput] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // Check if user is already logged in
    useEffect(() => {
        if (username) {
            navigate('/main');
        }
    }, [username, navigate]);

    const toggleForm = () => {
        setIsLogin(!isLogin);
        setMessage('');
    };

    const handleRegister = async () => {
        try {
            const response = await fetch('http://api.dreamstrl.net/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: usernameInput,
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    phone,
                    country,
                    city
                })
            });
            if (response.ok) {
                setMessage('Registration successful. Check your email for login details.');
            } else {
                const data = await response.json();
                setMessage(`Registration failed: ${data.message}`);
            }
        } catch (error) {
            setMessage('Error during registration. Please try again later.');
            console.error('Error during registration:', error);
        }
    };

    const handleLogin = async () => {
        try {
            const response = await fetch('http://api.dreamstrl.net/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: usernameInput, password })
            });
            if (response.ok) {
                setMessage('Login successful.');
                setUsername(usernameInput); // Update user context with logged-in username
                navigate('/main'); // Redirect to profile after login
            } else {
                const errorMessage = await response.text();
                setMessage(`Login failed: ${errorMessage}`);
            }
        } catch (error) {
            setMessage('Error during login. Please try again later.');
            console.error('Error during login:', error);
        }
    };

    return (
        <div id="home" className="auth">
            <video className="auth-video" autoPlay loop muted src="/assets/header-video.mp4" type="video/mp4" />
            <div className="auth-content">
                <img src="/assets/Logo Semi Colored.png" alt="Logo" className="auth-logo" draggable="true" onClick={() => navigate('/')} />
                <p className="logo-text">Click on logo to go back on home page</p>
                <div className="auth-box">
                    {message && <div className="message">{message}</div>}
                    {isLogin ? (
                        <div className="login-form">
                            <h2>Login</h2>
                            <input type="text" placeholder="Username" value={usernameInput} onChange={e => setUsernameInput(e.target.value)} />
                            <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                            <button className="auth-button" onClick={handleLogin}><strong>Login</strong></button>
                            <p>
                                Don't have an account?{' '}
                                <span className="form-toggle" onClick={toggleForm}>
                                    Register here
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className="register-form">
                            <h2>Register</h2>
                            <input type="text" placeholder="Username" value={usernameInput} onChange={e => setUsernameInput(e.target.value)} />
                            <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                            <input type="text" placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                            <input type="text" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
                            <input type="text" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                            <input type="text" placeholder="Country" value={country} onChange={e => setCountry(e.target.value)} />
                            <input type="text" placeholder="City" value={city} onChange={e => setCity(e.target.value)} />
                            <button className="auth-button" onClick={handleRegister}><strong>Register</strong></button>
                            <p>
                                Already have an account?{' '}
                                <span className="form-toggle" onClick={toggleForm}>
                                    Login here
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuthPage;
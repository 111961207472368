import React, { useState, useEffect } from 'react';
import './style/info.scss';

const Info = () => {
    const [isExpanded, setIsExpanded] = useState(true); // Initially expanded

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // Use the effect to set the initial expanded state when the user logs in
    useEffect(() => {
        setIsExpanded(true);
    }, []);

    return (
        isExpanded && (
            <div className="info">
                <div className="info__header">
                    <h2 className="info__title">
                        Information
                    </h2>
                    <span className="info__close" onClick={toggleExpand}>×</span>
                </div>
                <p className="info__content">
                    Welcome to our client information section. Here you will find important updates and notifications. Please stay tuned for any upcoming events and announcements. We value your trust and are here to provide the best service possible.
                </p>
            </div>
        )
    );
};

export default Info;
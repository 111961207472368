import React from 'react';
import './mainpage.scss'; // Create a CSS file for styling
import './pages/style/layout.scss'; // Import layout style
import Navbar from './pages/mNavbar'; // Adjust import paths according to your folder structure
import Info from './pages/Info';
import Profile from './pages/Profile';
import Services from './pages/mServices';
import Footer from './pages/mFooter';
import { useUser } from '../userContext'; // Import useUser hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const MainPage = () => {
    const { setUsername } = useUser(); // Get setUsername from context
    const navigate = useNavigate(); // Instantiate navigate

    const handleLogout = () => {
        setUsername(null); // Clear the username in context and localStorage
        navigate('/authorisation'); // Redirect to authorisation page
    };

    return (
        <div className="main">
            <video
                className="main-video"
                autoPlay
                loop
                muted
                src="/assets/header-video.mp4"
                type="video/mp4"
            />
            <Navbar/>
            <Info/>
            <div className="layout">
                <Profile/>
                <Services/>
            </div>
                <Footer/>
        </div>
    );
};

export default MainPage;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './style/customers.scss';

const Customers = () => {
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        country: '',
        city: '',
        status: 'client' // Default status
    });

    useEffect(() => {
        axios.get('http://api.dreamstrl.net/api/users')
             .then(response => setUsers(response.data))
             .catch(error => console.error('Error fetching users:', error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleEdit = (user) => {
        setEditingUser(user.id);
        setFormData({
            username: user.username,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            country: user.country,
            city: user.city,
            status: user.status
        });
    };

    const handleUpdate = () => {
        axios.put(`http://api.dreamstrl.net/api/users/${editingUser}`, formData)
             .then(response => {
                 setUsers(prevUsers => prevUsers.map(user => user.id === editingUser ? { ...user, ...formData } : user));
                 setEditingUser(null);
             })
             .catch(error => console.error('Error updating user:', error));
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            axios.delete(`http://api.dreamstrl.net/api/users/${id}`)
                 .then(response => {
                     setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
                 })
                 .catch(error => console.error('Error deleting user:', error));
        }
    };

    return (
        <div className="customers">
            <h2>Customers</h2>
            <table>
                <thead>
                <tr>
                    <th>Username</th>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Country</th>
                    <th>City</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {users.map(user => (
                    editingUser === user.id ? (
                        <tr key={user.id} className="editing-row">
                            <td><input type="text" name="username" value={formData.username} onChange={handleChange} /></td>
                            <td><input type="text" name="email" value={formData.email} onChange={handleChange} /></td>
                            <td><input type="text" name="first_name" value={formData.first_name} onChange={handleChange} /></td>
                            <td><input type="text" name="last_name" value={formData.last_name} onChange={handleChange} /></td>
                            <td><input type="text" name="phone" value={formData.phone} onChange={handleChange} /></td>
                            <td><input type="text" name="country" value={formData.country} onChange={handleChange} /></td>
                            <td><input type="text" name="city" value={formData.city} onChange={handleChange} /></td>
                            <td>
                                <select name="status" value={formData.status} onChange={handleChange}>
                                    <option value="client">Client</option>
                                    <option value="member">Member</option>
                                </select>
                            </td>
                            <td>
                                <button className="save-button" onClick={handleUpdate}>Save</button>
                                <button className="cancel-button" onClick={() => setEditingUser(null)}>Cancel</button>
                            </td>
                        </tr>
                    ) : (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.phone}</td>
                            <td>{user.country}</td>
                            <td>{user.city}</td>
                            <td>{user.status}</td>
                            <td>
                                <button className="edit-button" onClick={() => handleEdit(user)}>Edit</button>
                                <button className="delete-button" onClick={() => handleDelete(user.id)}>Delete</button>
                            </td>
                        </tr>
                    )
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Customers;
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './authContext';

const PrivateRoute = ({ element }) => {
    const { isLoggedIn } = useAuth();

    return isLoggedIn ? element : <Navigate to="/authorisation" replace />;
};

export default PrivateRoute;
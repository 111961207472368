import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './components/userContext';
import { TicketProvider } from './components/ticketContext';
import { ServiceProvider } from './components/serviceContext';
import { AuthProvider } from './components/authContext';
import PrivateRoute from './components/privateRoute';
import LandingPage from './components/landing/LandingPage';
import AuthPage from './components/auth/AuthPage';
import MainPage from './components/main/MainPage';
import DashboardPage from './components/Dashboard/DashboardPage';

function App() {
    return (
        <AuthProvider>
            <UserProvider>
                <ServiceProvider>
                    <TicketProvider>
                        <Router>
                            <div className="App">
                                <Routes>
                                    <Route path="/" element={<LandingPage />} />
                                    <Route path="/authorisation" element={<AuthPage />} />
                                    <Route path="/main" element={<PrivateRoute element={<MainPage />} />} />
                                    <Route path="/dashboard" element={<PrivateRoute element={<DashboardPage />} />} />
                                </Routes>
                            </div>
                        </Router>
                    </TicketProvider>
                </ServiceProvider>
            </UserProvider>
        </AuthProvider>
    );
}

export default App;
import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const ServiceContext = createContext();

export const useService = () => {
    const context = useContext(ServiceContext);
    if (!context) {
        throw new Error('useService must be used within a ServiceProvider');
    }
    return context;
};

export const ServiceProvider = ({ children }) => {
    const [services, setServices] = useState([]);

    const fetchServices = async (username) => {
        try {
            const response = await axios.get(`http://api.dreamstrl.net/api/services?username=${username}`);
            setServices(response.data);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    const addService = async (service) => {
        try {
            await axios.post('http://api.dreamstrl.net/api/services', service);
            fetchServices(service.username); // Refresh the list
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    const updateService = async (id, service) => {
        try {
            await axios.put(`http://api.dreamstrl.net/api/services/${id}`, service);
            fetchServices(service.username); // Refresh the list
        } catch (error) {
            console.error('Error updating service:', error);
        }
    };

    const deleteService = async (id, username) => {
        try {
            await axios.delete(`http://api.dreamstrl.net/api/services/${id}`);
            fetchServices(username); // Refresh the list
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    return (
        <ServiceContext.Provider value={{ services, fetchServices, addService, updateService, deleteService }}>
            {children}
        </ServiceContext.Provider>
    );
};